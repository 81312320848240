import React from "react"
import { Router } from "@reach/router"
import PrivateRoute from "../../components/context/privateRoute"
import FullTests from "../../components/app/full-tests"
import AppLayout from "../../components/app/app-components/layout"

const AppFullTests = ({ path }) => {
  return (
    <AppLayout title="Subject Tests" path={path} isBackButton={true}>
      <Router>
        <PrivateRoute
          path="/app/subjecttests"
          component={FullTests}
          isSubjectWise="true"
        />
      </Router>
    </AppLayout>
  )
}

export default AppFullTests
